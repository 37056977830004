import { OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EnvironmentData } from './services/environments.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(environmentData, title) {
        this.environmentData = environmentData;
        this.title = title;
        this.envLoaded = false;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.title.setTitle("Workbench");
        this.environmentData.fetchEnvData().subscribe(function (response) {
            if (response) {
                _this.envLoaded = true;
            }
        });
    };
    return AppComponent;
}());
export { AppComponent };
