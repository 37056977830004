import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'escapeScript'
})
export class EscapeScriptPipe implements PipeTransform {
  
  private blackListedCharacters: any[] = null;

  contructor() {
    this.blackListedCharacters = [
        { regex: new RegExp('%3C', 'ig'), value: '<' },
        { regex: new RegExp('U+003C', 'ig'), value: '<' },
        { regex: new RegExp('0x3C', 'ig'), value: '<' },
        { regex: new RegExp('0x003C', 'ig'), value: '<' },
        { regex: new RegExp('0x0000003C', 'ig'), value: '<' },
        { regex: new RegExp('&#60;', 'ig'), value: '<' },
        { regex: new RegExp('&#x3C;', 'ig'), value: '<' },
        { regex: new RegExp('&lt;', 'ig'), value: '<' },

        { regex: new RegExp('%3E', 'ig'), value: '>' },
        { regex: new RegExp('U+003E', 'ig'), value: '>' },
        { regex: new RegExp('0x3E', 'ig'), value: '>' },
        { regex: new RegExp('0x003E', 'ig'), value: '>' },
        { regex: new RegExp('0x0000003E', 'ig'), value: '>' },
        { regex: new RegExp('&#62;', 'ig'), value: '>' },
        { regex: new RegExp('&#x3E;', 'ig'), value: '>' },
        { regex: new RegExp('&gt;', 'ig'), value: '>' }
      ];
  }
  
  isValueValid(s: any) {
    if (typeof s !== 'string') {
      return true;
    }
    const pattern = new RegExp(/<\/?[^>]*>/);
    return !pattern.test(s);
  }

  isValueValidRecursively(payload: any) {
    if (payload) {
      if (payload instanceof String || typeof payload == 'string') {
        return this.isValueValid(payload);
      } else if (payload instanceof Array) {
        for (let index = 0; index < payload.length; index++) {
          if (!this.isValueValid(payload[index])) {
            return false;
          }
          
        }
      } else if (Object.keys(payload).length > 0) {
        for (let key of Object.keys(payload)) {
          if (!this.isValueValid(payload[key])) {
            return false;
          }
          
        }
      }
    }

    return true;
  }

  transform(s: any): any {
    if (typeof s !== 'string') {
      return '';
    }

    this.blackListedCharacters.forEach(item => {
      s = s.replace(item.regex, item.value);
    });
    
    return s.split(/<\/?[^>]*>/).join("");
  }

  escapeRecursively(payload: any) {
    if (payload) {
      if (payload instanceof String || typeof payload == 'string') {
        return this.transform(payload);
      } else if (payload instanceof Array) {
        for (let index = 0; index < payload.length; index++) {
          payload[index] = this.escapeRecursively(payload[index]);
        }
      } else if (Object.keys(payload).length > 0) {
        for (let key of Object.keys(payload)) {
          payload[key] = this.escapeRecursively(payload[key]);
        }
      }
    }

    return payload;
  }

 
}
