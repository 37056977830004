import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatWidgetComponent } from './chat-widget/chat-widget.component';
import {MatSnackBarModule, MatCheckboxModule, MatCardModule, MatIconModule, MatProgressSpinnerModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { Select2Module } from 'ng2-select2';
import { NgxMaskModule } from 'ngx-mask'

import { MatInputModule } from '@angular/material';
import { MatNativeDateModule } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatFormFieldModule } from '@angular/material/form-field'
import { OwlModule } from 'ngx-owl-carousel';
import { DownloadCardViewComponent } from './download-card-viewer/download-card-viewer.component';
import { FileUploaderComponent } from './file-uploader';
import { CardViewComponent } from './card-viewer/card-viewer.component';
import { CardMessageComponent } from './card-message/card-message.component';
import { OfferingDocViewComponent } from './offering-doc-viewer/offering-doc-viewer.component';
import { FormViewerComponent } from './form-viewer/form-viewer.component';
import { DatepickerComponent } from './datepicker/datepicker.component'
import { AudioRecordingComponent } from './chat-recorder/audio-recording.component'
import { AudioPlayerComponent } from './chat-recorder/audio-player.component'
import { HoldableDirective } from './chat-recorder/holdable.directive';
import { EscapeScriptPipe } from './escape-script/escape-script.pipe';
import { OrderByPipe } from '../pipes/orderBy.pipe';

@NgModule({
  declarations: [
    OfferingDocViewComponent,
    ChatWidgetComponent, 
    DownloadCardViewComponent,
    FileUploaderComponent,
    CardViewComponent,
    CardMessageComponent,
    FormViewerComponent,
    DatepickerComponent,
    AudioRecordingComponent,
    AudioPlayerComponent,
    HoldableDirective,
    EscapeScriptPipe,
    OrderByPipe
  ],
  imports: [
    FormsModule,
    CommonModule,
    OwlModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatCardModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    Select2Module
  ],
  exports: [
    ChatWidgetComponent,
    EscapeScriptPipe
  ],
  providers: [
    EscapeScriptPipe
  ]
})
export class SharedModule { }
