import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EnvironmentData = /** @class */ (function () {
    function EnvironmentData(httpClient) {
        this.httpClient = httpClient;
        this.subject = new Subject();
        this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.envData = null;
    }
    EnvironmentData.prototype.fetchEnvData = function () {
        var _this = this;
        var url = './assets/env.json';
        this.httpClient.get(url, {
            headers: this.httpHeaders,
            observe: 'response',
            reportProgress: true,
        }).subscribe(function (response) {
            _this.subject.next(response.body);
            _this.envData = response.body;
        }, function (err) {
            _this.subject.error(err);
        });
        return this.subject.asObservable();
    };
    EnvironmentData.prototype.getEnvData = function () {
        return this.envData;
    };
    EnvironmentData.prototype.getEnvDataObservable = function () {
        return this.subject.asObservable();
    };
    EnvironmentData.ngInjectableDef = i0.defineInjectable({ factory: function EnvironmentData_Factory() { return new EnvironmentData(i0.inject(i1.HttpClient)); }, token: EnvironmentData, providedIn: "root" });
    return EnvironmentData;
}());
export { EnvironmentData };
