import { Component, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EnvironmentData  } from './services/environments.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  envLoaded: boolean= false;
  constructor(
    public environmentData : EnvironmentData,
    private title:Title
  ){}
  ngOnInit(): void {
    this.title.setTitle("Workbench");
    this.environmentData.fetchEnvData().subscribe(response=>{
      if(response){
        this.envLoaded= true;
       }
    });
  }
}
  