import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { EscapeScriptPipe } from '../shared/escape-script/escape-script.pipe';

@Injectable()
export class EscapeScriptInterceptor implements HttpInterceptor {
  constructor(
    private escaeScriptPipe: EscapeScriptPipe
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url && req.body) {
      // const escapedBody = this.escaeScriptPipe.escapeRecursively(JSON.parse(JSON.stringify(req.body)));

      // if (JSON.stringify(req.body) === JSON.stringify(escapedBody)) {
      //   // let original req go ahead
      // } else {
      //   req = req.clone({
      //     body: escapedBody
      //   });
      // }
    }

    return next.handle(req);
  }
}