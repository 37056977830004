import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler,
    HttpRequest, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EnvironmentData  } from './environments.service';


@Injectable()
export class WorkbenchInterceptor implements HttpInterceptor {
    environmentUrl: any= [];
    constructor( public environmentData: EnvironmentData) {
    }

    dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let envData = this.environmentData.getEnvData();
        if (envData && req.url && (req.url.includes(envData.converse) || req.url.includes(envData.getMessages) || req.url.includes(envData.pollUnreadMessages)) && req.body) {
            const parsedBody = JSON.parse(JSON.stringify(req.body), (key, value) => {
                if (value && typeof value === "string" && this.dateFormat.test(value)) {
                    const tempDate = new Date(value);
                    return {
                        "$date": tempDate.getTime()
                    };
                }

                return value;
            });

            if (JSON.stringify(req.body) === JSON.stringify(parsedBody)) {
                // let original req go ahead
            } else {
                req = req.clone({
                    body: parsedBody
                });
            }
        }
        return next.handle(req).pipe(map(
            (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    let isBodyModified = false
                    if (event.body) {
                        const parsedResponseBody = JSON.parse(JSON.stringify(event.body), (key, value) => {
                            if (value) {
                              if (typeof value === "string" || typeof value === "number" || typeof value === "boolean" || value instanceof Array) {
                                return value;
                              } else if (value instanceof Object && Object.keys(value).length == 1 && value.hasOwnProperty("$date")) {
                                isBodyModified = true;
                                return new Date(value["$date"]);
                              }
                            }
                        
                            return value;
                          });
                
                          if (isBodyModified) {
                            const parsedResponse = event.clone({
                              body: parsedResponseBody
                            });
                            return parsedResponse;
                        }
                    }
                }
                return event;
            }
        ));
    }
}
