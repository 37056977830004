import { Routes } from '@angular/router';
var routes = [
    { path: '', redirectTo: "/wb", pathMatch: "full" },
    { path: 'wb', loadChildren: "./workbench/workbench.module#WorkbenchModule" }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
