import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentData } from './environments.service';
var WorkbenchInterceptor = /** @class */ (function () {
    function WorkbenchInterceptor(environmentData) {
        this.environmentData = environmentData;
        this.environmentUrl = [];
        this.dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    }
    WorkbenchInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var envData = this.environmentData.getEnvData();
        if (envData && req.url && (req.url.includes(envData.converse) || req.url.includes(envData.getMessages) || req.url.includes(envData.pollUnreadMessages)) && req.body) {
            var parsedBody = JSON.parse(JSON.stringify(req.body), function (key, value) {
                if (value && typeof value === "string" && _this.dateFormat.test(value)) {
                    var tempDate = new Date(value);
                    return {
                        "$date": tempDate.getTime()
                    };
                }
                return value;
            });
            if (JSON.stringify(req.body) === JSON.stringify(parsedBody)) {
                // let original req go ahead
            }
            else {
                req = req.clone({
                    body: parsedBody
                });
            }
        }
        return next.handle(req).pipe(map(function (event) {
            if (event instanceof HttpResponse) {
                var isBodyModified_1 = false;
                if (event.body) {
                    var parsedResponseBody = JSON.parse(JSON.stringify(event.body), function (key, value) {
                        if (value) {
                            if (typeof value === "string" || typeof value === "number" || typeof value === "boolean" || value instanceof Array) {
                                return value;
                            }
                            else if (value instanceof Object && Object.keys(value).length == 1 && value.hasOwnProperty("$date")) {
                                isBodyModified_1 = true;
                                return new Date(value["$date"]);
                            }
                        }
                        return value;
                    });
                    if (isBodyModified_1) {
                        var parsedResponse = event.clone({
                            body: parsedResponseBody
                        });
                        return parsedResponse;
                    }
                }
            }
            return event;
        }));
    };
    return WorkbenchInterceptor;
}());
export { WorkbenchInterceptor };
