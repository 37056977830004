import { EscapeScriptPipe } from '../shared/escape-script/escape-script.pipe';
var EscapeScriptInterceptor = /** @class */ (function () {
    function EscapeScriptInterceptor(escaeScriptPipe) {
        this.escaeScriptPipe = escaeScriptPipe;
    }
    EscapeScriptInterceptor.prototype.intercept = function (req, next) {
        if (req.url && req.body) {
            // const escapedBody = this.escaeScriptPipe.escapeRecursively(JSON.parse(JSON.stringify(req.body)));
            // if (JSON.stringify(req.body) === JSON.stringify(escapedBody)) {
            //   // let original req go ahead
            // } else {
            //   req = req.clone({
            //     body: escapedBody
            //   });
            // }
        }
        return next.handle(req);
    };
    return EscapeScriptInterceptor;
}());
export { EscapeScriptInterceptor };
