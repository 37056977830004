import { PipeTransform } from '@angular/core';
var EscapeScriptPipe = /** @class */ (function () {
    function EscapeScriptPipe() {
        this.blackListedCharacters = null;
    }
    EscapeScriptPipe.prototype.contructor = function () {
        this.blackListedCharacters = [
            { regex: new RegExp('%3C', 'ig'), value: '<' },
            { regex: new RegExp('U+003C', 'ig'), value: '<' },
            { regex: new RegExp('0x3C', 'ig'), value: '<' },
            { regex: new RegExp('0x003C', 'ig'), value: '<' },
            { regex: new RegExp('0x0000003C', 'ig'), value: '<' },
            { regex: new RegExp('&#60;', 'ig'), value: '<' },
            { regex: new RegExp('&#x3C;', 'ig'), value: '<' },
            { regex: new RegExp('&lt;', 'ig'), value: '<' },
            { regex: new RegExp('%3E', 'ig'), value: '>' },
            { regex: new RegExp('U+003E', 'ig'), value: '>' },
            { regex: new RegExp('0x3E', 'ig'), value: '>' },
            { regex: new RegExp('0x003E', 'ig'), value: '>' },
            { regex: new RegExp('0x0000003E', 'ig'), value: '>' },
            { regex: new RegExp('&#62;', 'ig'), value: '>' },
            { regex: new RegExp('&#x3E;', 'ig'), value: '>' },
            { regex: new RegExp('&gt;', 'ig'), value: '>' }
        ];
    };
    EscapeScriptPipe.prototype.isValueValid = function (s) {
        if (typeof s !== 'string') {
            return true;
        }
        var pattern = new RegExp(/<\/?[^>]*>/);
        return !pattern.test(s);
    };
    EscapeScriptPipe.prototype.isValueValidRecursively = function (payload) {
        if (payload) {
            if (payload instanceof String || typeof payload == 'string') {
                return this.isValueValid(payload);
            }
            else if (payload instanceof Array) {
                for (var index = 0; index < payload.length; index++) {
                    if (!this.isValueValid(payload[index])) {
                        return false;
                    }
                }
            }
            else if (Object.keys(payload).length > 0) {
                for (var _i = 0, _a = Object.keys(payload); _i < _a.length; _i++) {
                    var key = _a[_i];
                    if (!this.isValueValid(payload[key])) {
                        return false;
                    }
                }
            }
        }
        return true;
    };
    EscapeScriptPipe.prototype.transform = function (s) {
        if (typeof s !== 'string') {
            return '';
        }
        this.blackListedCharacters.forEach(function (item) {
            s = s.replace(item.regex, item.value);
        });
        return s.split(/<\/?[^>]*>/).join("");
    };
    EscapeScriptPipe.prototype.escapeRecursively = function (payload) {
        if (payload) {
            if (payload instanceof String || typeof payload == 'string') {
                return this.transform(payload);
            }
            else if (payload instanceof Array) {
                for (var index = 0; index < payload.length; index++) {
                    payload[index] = this.escapeRecursively(payload[index]);
                }
            }
            else if (Object.keys(payload).length > 0) {
                for (var _i = 0, _a = Object.keys(payload); _i < _a.length; _i++) {
                    var key = _a[_i];
                    payload[key] = this.escapeRecursively(payload[key]);
                }
            }
        }
        return payload;
    };
    return EscapeScriptPipe;
}());
export { EscapeScriptPipe };
