import { BrowserModule,Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { MatInputModule, MatFormFieldModule, MatNativeDateModule, MatDatepickerModule } from '@angular/material';
import { WorkbenchInterceptor } from './services/interceptors';
import { EscapeScriptInterceptor } from './services/escape-script.interceptor';
import { SharedModule } from './shared/shared.module';
/* import { SidebarComponent } from './sidebar/sidebar.component'; */
import { NgxImageCompressService} from "ngx-image-compress";

@NgModule({
  declarations: [
    AppComponent
    ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ScrollToModule.forRoot(),
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: WorkbenchInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: EscapeScriptInterceptor,
      multi: true
    },
    NgxImageCompressService,
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
